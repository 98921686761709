@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  line-height: inherit;
  min-height: 100%;
}

.belee-gradient-color {
  @apply bg-gradient-to-br from-[#2B3D40] to-[#19191A];
}

.belee-gradient-color-input {
  @apply bg-gradient-to-br from-[#3f5255] to-[#36363a];
}
.button-belee {
  @apply h-12 bg-green-belee font-medium rounded-md hover:bg-dark-green-belee;
}
.belee-gradient-dark-to-green {
  @apply bg-gradient-to-bl from-[#171c1d] to-[#70A7A9];
}

.btn-sp-belee {
  @apply h-12 rounded-lg text-xl px-10 shadow-md shadow-gray-600;
}
.btn-gray-belee {
  @apply border h-12 rounded-lg border-gray-400 bg-gray-500 text-sm hover:bg-gray-700;
}

.btn-gray-2 {
  @apply h-11 rounded-lg text-white  bg-[#77787C] px-6 hover:bg-gray-500 hover:text-green-belee;
}

.triangle-code {
  display: inline-block;
  height: 0;
  width: 0;
  border-right: 32px solid transparent;
  border-bottom: 16px solid #5e5f65;
  border-left: 32px solid transparent;
  border-radius: 40px 40px 0 0;
}

.dropdown-container {
  text-align: left;
  position: relative;
  border-radius: 5px;
}

.dropdown-input {
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;
}

.dropdown-menu {
  position: absolute;
  transform: translateY(4px);
  width: 100%;
  border-radius: 5px;
  overflow: auto;
  max-height: 150px;
  z-index: 99;
}

.dropdown-item {
  padding: 5px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #2b303670;
}

.dropdown-item.selected {
  background-color: #6dc8b8;
  color: #fff;
}

.dropdown-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.dropdown-tag-item {
  padding: 2px 4px;
  border-radius: 2px;
  display: flex;
  align-items: center;
}

.dropdown-tag-close {
  display: flex;
  align-items: center;
}

.search-box {
  padding: 5px;
}

.search-box input {
  width: 100%;
  box-sizing: border-box;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.dropdown-service {
  position: absolute;
  top: 0;
  left: 252px;
}

.commentaireBlock::-webkit-scrollbar {
  width: 4px;
}

.commentaireBlock::-webkit-scrollbar-thumb {
  background-color: #4caf50;
  border-radius: 6px;
}

.commentaireBlock::-ms-scrollbar {
  width: 12px;
}

.commentaireBlock::-ms-scrollbar-thumb {
  background-color: #4caf50;
  border-radius: 6px;
}
